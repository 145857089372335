@font-face {
  font-family: "Bravura";
  src: url("./fonts/bravura/eot/Bravura.eot");
  src: url(./fonts/bravura/eot/Bravura.eot?#iefix) format("embedded-opentype"),
    url(./fonts/bravura/woff/Bravura.woff) format("woff"), url(./fonts/bravura/woff/Bravura.woff2) format("woff2"),
    url(./fonts/bravura/otf/Bravura.otf) format("opentype"), url(./fonts/bravura/svg/Bravura.svg#bravura) format("svg");
  font-weight: normal;
  font-style: normal;
}

span.bravura {
  font-family: "Bravura";
}

html * {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
